import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@mui/material";
import ShopfloorPieChartSkeleton from "../../../../charts/shopfloorPieChart/skeleton";
import IconSkeleton from "./skeleton/iconSkeleton";
import useStyles from "./styles";
import WithToggleableTooltip from "../../../../withToggableTooltip";
import { IList } from "../../../../../shared/components";

interface IProps {
  footer: string;
  description: string;
  label: string;
  sublabel: string;
  isVisible: boolean;
  isLoading?: boolean;
  icon?: React.ReactElement;
  children?: React.ReactElement;
  isPieChart?: boolean;
}

function KPIDetailChart({
  description,
  footer,
  label,
  sublabel,
  isVisible,
  isLoading,
  icon,
  children,
  isPieChart = true,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const isLoadingContent = useMemo(
    () =>
      isPieChart ? (
        <ShopfloorPieChartSkeleton />
      ) : (
        <Skeleton variant="text" width={120} height={60} />
      ),
    [isPieChart],
  );

  const renderContent = useCallback(
    () => (
      <IList
        actionComponent={isLoading ? isLoadingContent : children}
        isActionComponentCentered={true}
        icon={isLoading ? <IconSkeleton /> : icon}
        iconToLabelSpace={40}
        label={label}
        labelColor="primary"
        labelVariant="caption"
        styles={{
          listItem: classes.kpiListItem,
          actionItem: classes.actionItem,
          GridActionItems: classes.gridActionItems,
        }}
        isLoading={isLoading}
      />
    ),
    [isLoading, children, icon, label, classes.kpiListItem],
  );

  const renderNoData = useCallback(
    () => (
      <IList
        label={`${t("dashboard.shopfloor.kpiDetailChart.noData")} ${label}`}
        labelColor="primary"
        labelVariant="caption"
        styles={{
          listItem: classes.kpiListItem,
        }}
      />
    ),
    [label, t, classes.kpiListItem],
  );

  const tooltipDescription = isLoading
    ? t("common.kpi_skeleton.description")
    : description;
  const tooltipLabel = isLoading ? t("common.kpi_skeleton.label") : label;
  const tooltipSublabel = isLoading
    ? t("common.kpi_skeleton.sublabel")
    : sublabel;
  const tooltipFooter = isLoading ? t("common.kpi_skeleton.footer") : footer;

  let content: React.ReactNode;

  if (isLoading || isVisible) {
    content = renderContent();
  } else {
    content = renderNoData();
  }

  return (
    <WithToggleableTooltip
      description={tooltipDescription}
      label={tooltipLabel}
      sublabel={tooltipSublabel}
      footer={tooltipFooter}
    >
      {content}
    </WithToggleableTooltip>
  );
}
export default React.memo(KPIDetailChart);
