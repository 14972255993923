import React, { useCallback } from "react";
import {
  GridEditCellProps,
  GridRowId,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import updateDataField from "../../../../tableHelper/updateDataField";
import GeorgDataGrid from "../../../../../../../shared/components/georgDataGrid";
import IManualStateConfigEntry from "../../../../../../../api/manualState/IManualStateConfigEntry";
import { useTranslation } from "react-i18next";
import useStyles from "../../styles";
import getColumnConfig from "./getColumnConfig";

interface IProps {
  onTableUpdate: (newManualStates: IManualStateConfigEntry[]) => void;
  data: IManualStateConfigEntry[];
  onRemove: (id: string) => void;
  onIsVisibleChange: (id: string, isVisible: boolean) => void;
  onMoveManualInputUp: (id: string) => void;
  onMoveManualInputDown: (id: string) => void;
  isLoading: boolean;
}

export default function ManualStateCRUDTable({
  onTableUpdate,
  data,
  onRemove,
  onIsVisibleChange,
  onMoveManualInputUp,
  onMoveManualInputDown,
  isLoading,
}: IProps): React.ReactElement {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const handleRowUpdate = useCallback(
    (newRow: GridEditCellProps, oldRow: GridEditCellProps) => {
      const { id } = newRow;
      for (const field in oldRow) {
        if (newRow[field] !== oldRow[field]) {
          const value = newRow[field];
          const newData = updateDataField(data, id, field, value);
          onTableUpdate(newData as IManualStateConfigEntry[]);
          break;
        }
      }
      return newRow;
    },
    [data, onTableUpdate],
  );
  return (
    <GeorgDataGrid
      onSelectionModelChange={(selectionModel: GridRowSelectionModel) => {
        const newData = data.map((entry: IManualStateConfigEntry) => {
          return { ...entry, active: selectionModel.includes(entry.id) };
        });
        onTableUpdate(newData);
      }}
      processRowUpdate={handleRowUpdate}
      columns={getColumnConfig(
        classes,
        t,
        i18n.language,
        isLoading,
        (rowId: GridRowId) => {
          onRemove(rowId as string);
        },
        (id: GridRowId, isVisible: boolean) => {
          onIsVisibleChange(id as string, isVisible);
        },
        (id: GridRowId) => onMoveManualInputUp(id as string),
        (id: GridRowId) => onMoveManualInputDown(id as string),
      )}
      disableSelectionOnClick={true}
      disableColumnSelector={true}
      rows={data}
      isLoading={isLoading}
    />
  );
}
