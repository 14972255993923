import React from "react";
import { Grid2 as Grid } from "@mui/material";

interface IProps {
  id: string;
  isSidebarOpen?: boolean;
  isSmallView?: boolean;
  children: React.ReactElement;
  cardStyle?: string;
  elementsPerRow: number;
}

export default function CardGridItem({
  id,
  isSmallView,
  isSidebarOpen,
  children,
  cardStyle,
  elementsPerRow,
}: IProps): React.ReactElement {
  const paddingTop = isSmallView ? 16 : 0;
  const itemWidth = 12 / elementsPerRow;
  return (
    <Grid
      key={id}
      size={{
        lg: isSidebarOpen ? 12 : itemWidth,
        xl: isSmallView ? 12 : itemWidth,
      }}
      style={{ paddingTop, paddingLeft: 16 }}
      className={cardStyle}
    >
      <>{children}</>
    </Grid>
  );
}
