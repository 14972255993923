import React, { useMemo } from "react";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarFilterButtonProps,
} from "@mui/x-data-grid";

type ToolbarButtonProps = React.ForwardRefExoticComponent<
  GridToolbarFilterButtonProps & React.RefAttributes<HTMLButtonElement>
>;

type ToolbarButton = {
  component: ToolbarButtonProps;
};

function createToolbarButton(
  Component: ToolbarButtonProps,
  index: number,
  isDisabled: boolean,
) {
  // return <Component key={index} disabled={disabled} />;
  return (
    <span
      key={index}
      style={{
        pointerEvents: isDisabled ? "none" : "auto",
        opacity: isDisabled ? 0.5 : 1,
      }}
    >
      <Component />
    </span>
  );
}

export const CustomToolbarButtons: React.FC<{ isLoading: boolean }> = ({
  isLoading,
}) => {
  const toolbarButtons: ToolbarButton[] = useMemo(
    () => [
      {
        component: GridToolbarDensitySelector as ToolbarButtonProps,
      },
      {
        component: GridToolbarColumnsButton as ToolbarButtonProps,
      },
      {
        component: GridToolbarFilterButton as ToolbarButtonProps,
      },
      {
        component: GridToolbarExport as ToolbarButtonProps,
        disabled: isLoading,
      },
    ],
    [],
  );
  return (
    <GridToolbarContainer>
      {toolbarButtons.map(({ component }, index) =>
        createToolbarButton(component, index, isLoading),
      )}
    </GridToolbarContainer>
  );
};
