/*
 *
 *   Higher order component for wrapping components with a tooltip.
 *
 */

import React, { useCallback, useMemo } from "react";
import { Tooltip, Typography } from "@mui/material";
import useStyles from "./styles";
export type TPlacement =
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "right"
  | "top-end"
  | "top-start"
  | "top";

//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.
interface Props {
  children: JSX.Element;
  description?: string;
  label?: string; // when the text is separated by \n, it will be displayed as a list
  sublabel?: string;
  footer?: string;
  smallTooltip?: boolean;
  centerContent?: boolean;
  noOverflow?: boolean;
  placement?: TPlacement;
  isFullWidthContainer?: boolean;
  hasBulletPoints?: boolean;
  isFirstHasBulletPoint?: boolean;
}

//------------------------------------------------------------------------------------------------------------
// Component.
export default function WithTooltip({
  children,
  description,
  label,
  sublabel,
  footer,
  smallTooltip = false,
  centerContent = false,
  noOverflow = false,
  placement = "right-end",
  isFullWidthContainer = false,
  hasBulletPoints = false,
  isFirstHasBulletPoint = false,
}: Props): React.ReactElement {
  const { classes } = useStyles();

  const sentences = useMemo(() => label?.split("\n").filter(Boolean), [label]);
  const isMultiline = (sentences?.length ?? 0) > 1;

  const renderLine = useCallback(
    (sentence: string, index: number, variant: "body2" | "h2") => (
      <Typography key={index} color="primary" variant={variant}>
        {isMultiline &&
        hasBulletPoints &&
        (index === 0 ? isFirstHasBulletPoint : true)
          ? "• "
          : ""}
        {sentence}
      </Typography>
    ),
    [isMultiline, hasBulletPoints, isFirstHasBulletPoint],
  );

  const renderContent = (variant: "body2" | "h2") => (
    <>
      {sentences?.map((sentence, index) =>
        renderLine(sentence, index, variant),
      )}
      {sublabel && (
        <Typography
          className={classes.sublabel}
          color="primary"
          variant="caption"
        >
          {sublabel}
        </Typography>
      )}
      {description && (
        <Typography
          className={classes.description}
          color="primary"
          variant="body1"
        >
          {description}
        </Typography>
      )}
      {footer && (
        <Typography
          className={classes.footer}
          color="primary"
          variant="caption"
        >
          {footer}
        </Typography>
      )}
    </>
  );

  const renderTooltip = (variant: "body2" | "h2", tooltipClass: string) => (
    <Tooltip
      key={label || sublabel || description || ""}
      arrow
      title={renderContent(variant)}
      placement={placement}
      classes={{
        arrow: classes.arrow,
        tooltip: tooltipClass,
      }}
    >
      <div
        className={`${centerContent ? classes.tooltip_wrapper : ""} ${isFullWidthContainer ? classes.fullWidth : ""} ${noOverflow ? classes.no_overflow : ""}`}
      >
        {children}
      </div>
    </Tooltip>
  );

  return smallTooltip
    ? renderTooltip("body2", classes.smallTooltip)
    : renderTooltip("h2", classes.tooltip);
}
