import { makeStyles } from "tss-react/mui";
import { colors } from "../../../../../shared/theme";
import colorToRgba from "../../../../../helper/adjustColor";

const baseCardContainer = {
  transform: "perspective(1px) translateZ(0)",
  transitionDuration: "0.3s",
  transitionProperty: "transform",
};

const useStyles = makeStyles()({
  container: {
    marginLeft: 0,
    backgroundColor: colors.white_base,
    overflow: "auto",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
  },
  subContainer: {
    marginLeft: 0,
    padding: 0,
  },
  paddingBottom: {
    paddingBottom: 20,
  },
  contentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 0,
    width: "100%",
    justifyItems: "center",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
  },
  largeCardContainer: {
    padding: 0,
    ...baseCardContainer,
  },
  smallCardContainer: {
    ...baseCardContainer,
    paddingTop: 10,
  },
  textContainer: {
    textAlign: "left",
    maxWidth: 460,
    "&.MuiTypography-root": {
      fontWeight: "normal",
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "end",
  },
  largeImage: {
    height: 258,
    textAlign: "right",
  },
  smallImage: {
    width: "100%",
    maxWidth: 460,
    textAlign: "right",
  },
  linkContainer: {
    paddingLeft: "0px !important",
    display: "flex",
    alignItems: "end",
    flex: 1,
  },
  link: {
    fontSize: "14px",
    fontWeight: "bold",
    marginTop: "16",
    fontFamily: "Titillium Web,Roboto",
    paddingRight: 8,
  },
  disabledLink: {
    color: colorToRgba(colors.blue_base, 0.24),
    pointerEvents: "none",
    cursor: "default",
  },
  boldText: {
    "&.MuiTypography-root": {
      fontWeight: "bold",
    },
    display: "contents",
    lineHeight: "normal",
  },
  text: {
    display: "contents",
    lineHeight: "normal",
  },
});

export default useStyles;
